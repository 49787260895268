#vertical-navigation {
    width: $navigationWidth;
    min-width: $navigationWidth;
    max-width: $navigationWidth;
    overflow-x: hidden;
    transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2), width 0.1s linear, min-width 0.1s linear, max-width 0.1s linear;
    box-shadow: $whiteframe-shadow-6dp;

    .ms-khelpdesk-identifca{
        min-height: 80px;
        font-size: 16px;
        margin-top: 10px;
        margin-left: 24px;
        margin-bottom: -8px;
        md-input-container{
            margin: 0;
            input, md-icon{
                border: none;
                color: rgba(255,255,255,0.7)!important;
            }
            input{
                font-size: 13px;
            }
            .md-errors-spacer{
                display: none;
            }
        }
    }

    .img-logo{
        min-height: 50px;
        min-width: 150px;
        margin-left: -10px;
    }

    .navigation-header {
        height: 64px;
        min-height: 64px;
        background-color: rgba(255, 255, 255, 0.05);
        padding: 0 16px 0 24px;

        .logo {

            .logo-image {
                display: block;
                //background: material-color('light-blue', '600');
                background: #f4d800;
                width: 32px;
                min-width: 32px;
                height: 32px;
                line-height: 32px;
                text-align: center;
                font-size: 16px;
                font-weight: 500;
                color: #FFFFFF;
                border-radius: 2px;
            }

            .logo-text {
                color: #FFFFFF;
                margin-left: 16px;
                font-size: 16px;
            }
        }

        .fold-toggle {
            transition: transform 0.3s ease-in-out 0.1s;
            transform: rotate(0deg);
            margin: 0;
            padding: 8px;
            width: 34px !important;
            height: 34px !important;
            cursor: pointer;
        }
    }

    &:not(._md-locked-open) {
        box-shadow: $whiteframe-shadow-8dp;
    }
}

// Folded navigation
@media only screen and (min-width: $layout-breakpoint-sm) {

    .ms-navigation-folded {

        #content-container {
            margin-left: $navigationFoldedWidth;
        }

        #vertical-navigation {
            position: absolute;

            .navigation-header {

                .fold-toggle {
                    transform: rotate(180deg);
                    opacity: 0;
                }
            }
        }

        &.ms-navigation-folded-open {

            #vertical-navigation {

                .navigation-header {

                    .fold-toggle {
                        transition: transform 0.3s ease-in-out 0.1s, opacity 0.3s ease-in-out 0.2s;
                        opacity: 1;
                    }
                }
            }
        }

        &:not(.ms-navigation-folded-open) {

            #vertical-navigation {
                position: absolute;
                width: $navigationFoldedWidth;
                min-width: $navigationFoldedWidth;
                max-width: $navigationFoldedWidth;

                .navigation-header {
                    padding: 0 16px;

                    .logo-text {
                        display: none;
                    }
                }
            }
        }
    }

}