#chat-app {

    .left-sidenav {
        overflow: hidden;
        width: 400px;

        .view {
            overflow: hidden;
            z-index: 9999999;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background: #ffffff;
            opacity: 1 !important;
            transition-duration: 300ms;
            border-right: 1px solid rgba(0, 0, 0, 0.12);
        }
    }
}