#search {

    .content {

        .users-tab {

            .result-info {
                border-bottom: none;
            }
        }
    }
}