#search {

    .content {

        .contacts-tab {

            .result-info {
                border-bottom: none;
            }

            .results {

                .result-item {

                    .avatar-cell {
                        width: 88px;
                    }
                }
            }
        }
    }
}