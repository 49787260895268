#notes {
    height: 100%;

    > md-toolbar {
        background-image: url('/assets/images/backgrounds/header-bg.png');
        background-size: cover;

        .md-toolbar-tools {

            .main-title {
                .notes-count {
                    font-size: 14px;
                }
            }

            .search-input-wrapper {
                position: relative;
                font-size: 13px;

                label {
                    padding-right: 4px;
                    cursor: pointer;

                    md-icon {
                        color: rgba(255, 255, 255, 0.8);
                    }

                }

                md-input-container {

                    .md-errors-spacer {
                        display: none;
                    }

                    input {
                        border-bottom: 1px solid rgba(255, 255, 255, 0.8);
                        color: rgba(255, 255, 255, 0.8);
                        width: 240px;

                        &::placeholder {
                            color: rgba(255, 255, 255, 0.8);
                        }

                        &:focus {
                            border-bottom: 1px solid white;
                            color: white;
                        }

                    }
                }
            }
        }

        .search-toolbar {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            opacity: 1 !important;

            #search-notes-input {
                height: 100%;
                background: transparent;
                color: white;
                font-size: 16px;

                &::placeholder {
                    color: rgba(255, 255, 255, 0.8);
                }

            }
        }
    }

    > md-content {
        background: transparent;
        padding: 8px;

        .notes {
            max-width: 1200px;
            margin: 0 auto;
        }

        .no-results {
            margin-top: 20vh;

            .message {

                .message-icon {
                    margin: 16px;
                    color: rgba(0, 0, 0, 0.33);
                }
                .message-text {
                    font-size: 24px;
                    font-weight: 300;
                    color: rgba(0, 0, 0, 0.33);
                }
            }
        }
    }
}